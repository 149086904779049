// Imports
@import "./animations";
@import "./mixins";
@import "./overrides";
@import "./variables";

.blue-text {
    font-weight: bold;
    color: $blue;
}

.eloware-cc { 
    color: rgb(102, 177, 80);
    font-weight: bold;
}

.hyperlink {
    font-weight: bold;
    transition: 0.40s linear;
    transform: translateY(-0rem);

    &:hover {
        transform: translateY(-0.5rem);
    }
}

#introduction {
    flex-direction: unset;
    padding: 7.8rem 1.5rem;

    .introduction-container {
        .content {
            margin: 2.5rem auto 2rem auto;

            .title {
                color: $foreground;
            }
        }
    }
}

#about {
    .about-container {
        .about-description {
            color: $foreground;
            font-size: 18px;
            font-weight: normal;
        }
    
        .columns {
            padding: 12px 0px 12px 0px;
            h6 {
                color: $blue-desaturated;
                text-align: center;
            }

            .column {
                @include breakpoint(extra_small_devices) {
                    text-align: center;
                }

                .btn-alt {
                    font-weight: bold;
                    background-color: transparent;
                    color: $foreground;

                    @include breakpoint(extra_small_devices) {
                        border-radius: 4px;
                        background-color: #192022 !important;
                    }

                    @include breakpoint(small_devices) {
                        border: none !important;
                        margin: 3px !important;
                    }
                    
                    &:hover,
                    &:focus {
                        color: $foreground;
                        transform: translateX(0.5rem);
                    }
                }
            }
        }

        h1 {
            color: inherit !important;
            font-size: 36px !important;
            border-bottom: $blue-desaturated solid 4px;
            display: inline-block;
        }
    }
}

#pricing {
    background-color: $background-alt;
    padding: 6rem 1.5rem;

    transform: skewY(-3deg);

    .pricing-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transform: skewY(3deg);

        h1 {
            color: inherit !important;
            font-size: 26px;
            font-weight: bold;

            align-items: center;
            margin-bottom: 1rem;
        }
    }
}

#not-found {
    .not-found-container {
        color: $foreground;
        padding: 8rem;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .not-found-title {
            font-size: 3rem;
            margin-bottom: 1rem;
        }

        .not-found-description {
            text-align: center;
            font-size: 1.3rem;
            color: $foreground;
        }
    }
}

.container {
  width: 100%;
}

.packages {
  margin: 20px;
  width: 300px;
  padding-bottom: 1.5em;
  height: 100%;
  background-color: #0E1112;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 19px 38px #0e1112c2, 0 15px 12px #0e111263;
  flex-wrap: wrap;
  color: #f4f4f4;
}

h1, h2 {
  color: #c5c8c9;
  font-size: 1.8em;
}

h2 span { 
  font-size: 14px;
}

.list li {
  font-size: 20px;
  list-style: none;
  border-bottom: 1px solid #f4f4f4;
  padding-inline-start: 0;
  border-width: 1px;
  padding: 10px;
}

.first {
  margin-top: 40px;
  border-top: 1px solid #f4f4f4;
}

.list {
  width: 80%;
}

ol,
ul {
  padding: 0;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.developers-imgs {
  border-radius: 300px;
}

input,
label {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.button-price {
  padding: 10px 30px;
  text-decoration: none;
  font-size: 1.4em;
  margin: 15px 15px;
  border-radius: 50px;
  color: #f4f4f4;
  transition: all 0.3s ease 0s;
}

.button-price:hover { 
  background-color: rgb(102, 177, 80);
  color: #d8d7d7;
   box-shadow: 0 0 10px 0 rgb(102, 177, 80) inset, 0 0 20px 2px rgb(44, 100, 27);
}

.button:hover {
  transform: scale(1.2);
}

.button1 {
  background-color: rgb(102, 177, 80);
  box-shadow: 0 0 10px 0 rgb(102, 177, 80) inset, 0 0 20px 2px rgb(44, 100, 27);
}

.button2 {
  background-color: rgb(102, 177, 80);
  box-shadow: 0 0 10px 0 rgb(102, 177, 80) inset, 0 0 20px 2px rgb(44, 100, 27);
}

.button3 {
  background-color: rgb(102, 177, 80);
  box-shadow: 0 0 10px 0 rgb(102, 177, 80) inset, 0 0 20px 2px rgb(44, 100, 27);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e2321;
  -webkit-transition: 0.4s;

  box-shadow: 2px 6px 25px #1e2321;
  transform: translate(0px, 0px);
  transition: 0.6s ease transform, 0.6s box-shadow;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #50bfe6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #50bfe6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.package-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.packages:hover { 
      transform: scale(1.05);
    }