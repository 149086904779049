@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css");

.vm-i {
    font-family: "Victor Mono", monospace;
    font-style: italic;
}

$background: #0E1112;
$background-alt: #101415;
$background-alt-other: #131718;
$foreground: #c5c8c9;

$red: #f65b5b;
$yellow: #e7ac7e;
$green: #8dc776;
$aqua: #89b8c2;
$blue: #84a0c6;
$blue-desaturated: #758cb0;
$magenta: #bb8fe5;