@import "./variables";

// Default Overrides
html, body, .app {
    background-color: $background;
    color: $foreground;
    font-family: "DM Sans", sans-serif;
}

a {
    color: $blue;
    transition: 0.3s ease-in-out;

    &:hover,
    &:focus {
        color: rgb(102, 177, 80);
    }
}

ul, ol {
    list-style: square !important;

    @include breakpoint(extra_small_devices) {
        list-style: none !important;
        margin: 0px !important;
    }
}

// Bulma Overrides
.button {
    color: $foreground;
    background-color: transparent;
    border: 2px solid #192022;

    margin: 6px !important;
    transition: 0.40s;

    span {
        padding-left: 0.5rem;
    }

    &:hover,
    &:focus {
        border-color: rgb(102, 177, 80);
        color: inherit;
    }
}

.content {
    text-align: left;
    font-size: 18px;

    width: 100%;
}

.card {
    color: $foreground;
    background-color: $background;
    border-radius: 6px;

    margin: 12px;
    width: 420px;

    @include breakpoint(extra_small_devices) {
        width: 340px !important;
    }

    animation: scale-in .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    .card-content {
        .repo-title {
            font-weight: bold;
        }
    
        .repo-description {
            font-size: 14px;
        }
    }
}

.navbar {
    background-color: $background;

    .navbar-brand, .navbar-menu, .navbar-burger,.navbar-item {
        margin: 12px 8px;
        background-color: $background;
        color: $foreground;

    }

    .navbar-start {
        flex-grow: 1;
        justify-content: center;
    }

    .navbar-start, .navbar-center, .navbar-end {
        .navbar-item {
            background: none;
            margin: 0px 12px;
            border-bottom: 3px solid transparent;

            transition: 0.3s ease-in-out;

            &:hover,
            &:focus {
                color: $foreground;
                border-color: rgb(102, 177, 80);
            }
        }

        .is-icon {
            margin: 0px 4px !important;

            .is-hidden-desktop {
                margin: 0px 8px !important;
            }
        }
    }
}

.footer {
    background-color: $background;
    padding: 4rem !important;

    p {
        font-size: 16px;
    }
}

.developers {
    background-color: $background;
}

.support-area {
    background-color: $background;
}

.center-elemets {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.guilded-element {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  

.support-h1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 6%;
  }
  

.developers-row {
    text-align: center;
}

.developers-row img {
    filter: drop-shadow(8px 8px 10px rgba(15, 15, 15, 0.664));
    -webkit-filter: drop-shadow(8px 8px 10px rgba(15, 15, 15, 0.664));
    display: inline-block;
    margin: 1px;
    width: 100px;
    height: 100px;
}

.developers-row:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.developers-row a {
    color: $foreground;
    display: inline-block;
    margin: 9% !important;
}

.developers-row img:hover {
  -webkit-filter: contrast(50%); /* Safari 6.0 - 9.0 */
  filter: contrast(50%);
  content: 'dev'
}